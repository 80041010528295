export default function updateMark(markType, attrs, state, dispatch) {
  const { from, to } = state.selection;
  let tr = state.tr;

  const parseStyles = (oldStyle, attrs) => {
    const { fontSize, fontFamily, textColor, backgroundColor } = attrs;

    let styles = oldStyle
      .split(";")
      .filter(Boolean)
      .reduce((acc, style) => {
        const [key, value] = style.split(":").map((s) => s.trim());
        acc[key] = value;
        return acc;
      }, {});

    if (fontSize) {
      styles["font-size"] = `${fontSize}px`;
    }

    if (fontFamily) {
      styles["font-family"] = fontFamily;
    }

    if (textColor) {
      styles["color"] = textColor;
    }

    if (backgroundColor) {
      styles["background-color"] = backgroundColor;
    }

    return Object.entries(styles)
      .map(([key, value]) => `${key}: ${value}`)
      .join("; ");
  };

  state.doc.nodesBetween(from, to, (node, pos) => {
    // VARIABLE NODE
    if (node.type.name === "variable") {
      const newAttrs = { ...node.attrs, style: parseStyles(node.attrs.style, attrs) };

      tr = tr.setNodeMarkup(pos, node.type, newAttrs, node.marks);
    }

    // OTHER DEFAULT NODES
    if (node.isText) {
      const existingMark = markType.isInSet(node.marks);
      const markRange = {
        from: Math.max(pos, from),
        to: Math.min(pos + node.nodeSize, to),
      };

      if (existingMark) {
        tr = tr.removeMark(markRange.from, markRange.to, markType);

        const newMark = markType.create({ ...existingMark.attrs, ...attrs });
        tr = tr.addMark(markRange.from, markRange.to, newMark);
      } else {
        const newMark = markType.create(attrs);
        tr = tr.addMark(markRange.from, markRange.to, newMark);
      }
    }
  });

  if (tr.docChanged) {
    dispatch(tr);
    return true;
  }
  return false;
}
